.info .auto-1440 {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.info :deep(.breadcrumb) {
  flex-shrink: 0;
}

.info .section {
  flex: 1;
  height: 0;
  padding-bottom: 0.3rem;
}

.section {
  display: flex;
  padding-top: 0.35rem;
  padding-bottom: 1.1rem;
}

.section .left {
  flex: 1;
}

.section .right {
  width: 4.9rem;
  flex-shrink: 0;
  padding-left: 0.3rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.section h1 {
  font-size: 0.2rem;
  color: #333333;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 0.15rem;
  display: flex;
}

.section h1 span {
  flex: 1;
}

.section h1 .ant-btn {
  flex-shrink: 0;
}

.section .title-wrap {
  margin-bottom: 0.25rem;
  flex-shrink: 0;
}

.section .title-wrap .infoitem {
  display: flex;
  margin-bottom: 0.1rem;
}

.section .title-wrap .infoitem .label {
  font-size: 0.18rem;
  color: #000000;
  line-height: 0.3rem;
  width: 0.8rem;
  font-weight: bold;
  flex-shrink: 0;
}

.section .title-wrap .infoitem .inner {
  flex: 1;
}

.section .relateModel {
  flex: 1;
  height: 0;
  min-height: 2rem;
}

:deep(.section .relateModel > div) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:deep(.section .relateModel > div) .list {
  flex: 1;
  overflow-y: auto;
}

.section .des {
  font-size: 0.18rem;
  color: #555555;
  line-height: 1.778;
}

.section .iframe {
  height: 100%;
}

.section .iframe iframe {
  width: 100%;
  height: 11rem;
  border: 0;
}

.section .pdf_wrap {
  position: relative;
  height: 100%;
}

.section .pdf_wrap .btn {
  position: absolute;
  right: 100%;
  padding-right: 0.1rem;
}

.section .pdf_wrap .btn .item {
  width: 0.6rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  font-size: 0.17rem;
  color: #888888;
  border-radius: 0.03rem;
  background-color: #f5f5f5;
  text-align: center;
  line-height: 1.2;
  padding: 0 0.1rem;
  margin-bottom: 0.01rem;
  transition: 0.3s;
  cursor: pointer;
}

.section .pdf_wrap .btn .item.active,
.section .pdf_wrap .btn .item:hover {
  background-color: #00ffc0;
  color: #fff;
}

.goback {
  position: absolute;
  left: 0.5rem;
  top: 0.2rem;
}